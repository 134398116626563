body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 1.1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:hover {
  color: #fff;
  text-decoration-line: none;
}

ul {
  list-style: none;
}

.off-canvas-wrapper {
  background: #cd6;
}

.ok {
  border: 3px solid green;
}

.err {
  color: red;
}

.errField {
  border: 3px solid red !important;
}

h1,
h2 {
  font-weight: bold;
  margin: 0 auto;
  background: #fd8; /* Цвет фона */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Параметры тени */
  padding: 10px;
  border-radius: 50%;
  height: 100px;
  display: flex;
  align-items: center;
}

h1 {
  text-align: center;
}

р #feedback .form-group {
  width: 500px;
}

.tasks {
  background: inherit;
  display: flex;
  flex-direction: column;
}

.tasks th:hover {
  cursor: pointer;
}

#btnPrev,
#btnNext {
  display: none;
}

.successedAddedTaskMessage {
  position: absolute;
  top: 60%;
  left: 35%;
  z-index: 100;
}

.login {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.login button {
  margin-top: 10%;
}
